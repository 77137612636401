import React, { FC, ChangeEvent } from "react";

interface InputProps {
  placeholder?: string;
  label?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  className?: string;
  autoComplete?: string; // Added optional param for autocomplete
}

const Input: FC<InputProps> = ({
  label,
  placeholder,
  onChange,
  value,
  className,
  autoComplete,
}) => {
  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700  ml-1 ">
          {label}
        </label>
      )}
      <input
        className={`bg-slate-100 border border-slate-200 rounded focus:outline-blue-800 p-2 ${className}`}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        autoComplete={autoComplete} // Utilizing the new autoComplete prop
      />
    </div>
  );
};

export default Input;
