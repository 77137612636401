import React, { useState } from "react";

interface HourBlock {
  hour: number; // This is the hour in 24-hour format
  available: boolean;
}

// Converts 24-hour time to 12-hour format for display
const formatHour = (hour: number): string => {
  const hour12 = hour % 12 === 0 ? 12 : hour % 12;
  return `${hour12} ${hour >= 12 ? "pm" : "am"}`;
};

// Generate a 12-hour formatted array for side labels, starting at 5 AM and ending at 10 PM
const hours = Array.from({ length: 18 }, (_, i) => formatHour(5 + i));

const daysOfWeek = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];
interface AvailabilitySelectorProps {
  availability: HourBlock[][];

  setAvailability: (newAvailability: HourBlock[][]) => void;
}
const AvailabilitySelector: React.FC<AvailabilitySelectorProps> = ({
  availability,
  setAvailability,
}) => {
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [dragAction, setDragAction] = useState<
    "selecting" | "deselecting" | null
  >(null);

  const toggleAvailability = (
    day: number,
    hourIndex: number,
    forcedAction?: "selecting" | "deselecting"
  ) => {
    const newAvailability = availability.map((dayBlocks, i) =>
      i === day
        ? dayBlocks.map((block, index) =>
            index === hourIndex
              ? {
                  ...block,
                  available: forcedAction
                    ? forcedAction === "selecting"
                    : !block.available,
                }
              : block
          )
        : dayBlocks
    );
    setAvailability(newAvailability);
  };

  const handleMouseDown = (day: number, hourIndex: number) => {
    const action = availability[day][hourIndex].available
      ? "deselecting"
      : "selecting";
    setDragAction(action);
    toggleAvailability(day, hourIndex, action);
    setIsDragging(true);
  };

  const handleMouseEnter = (day: number, hourIndex: number) => {
    if (isDragging && dragAction) {
      toggleAvailability(day, hourIndex, dragAction);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setDragAction(null);
  };

  return (
    <div className="flex flex-col select-none">
      <div className="flex justify-center mb-0 ml-[65px]">
        {daysOfWeek.map((day, i) => (
          <div key={i} className="w-12 text-center font-medium text-slate-700">
            {day}
          </div>
        ))}
      </div>
      <div className="flex" onMouseLeave={handleMouseUp}>
        <div className="flex flex-col mr-4">
          {hours.map((hourLabel, i) => (
            <div
              key={i}
              className="h-10 flex items-start justify-end pr-2 w-[50px] text-sm text-slate-700"
            >
              {hourLabel}
            </div>
          ))}
        </div>
        {availability.map((day, dayIndex) => (
          <div key={dayIndex} className="flex flex-col">
            {day.map((hour, hourIndex) => (
              <div
                key={hourIndex}
                className={`m-[.3px] h-10 w-12 border  ${
                  hour.available
                    ? "bg-emerald-100 border-emerald-800 z-100"
                    : "bg-gray-100 border-gray-300"
                } cursor-cell`}
                onMouseDown={() => handleMouseDown(dayIndex, hourIndex)}
                onMouseEnter={() => handleMouseEnter(dayIndex, hourIndex)}
                onMouseUp={handleMouseUp}
              ></div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

export default AvailabilitySelector;
