import React, { FC, useEffect, useState } from "react";
import MenuBar from "../../components/MenuBar";
import Input from "../../components/UICore/Input";
import Button from "../../components/UICore/Button";
import TextLoop from "../../components/UICore/TextLoop";
import useUser from "../../hooks/useUser";
import { toast } from "react-toastify";
import Footer from "../../components/Footer";
const Landing: FC = () => {
  const [email, setEmail] = useState<string>("");
  const { signupToWaitlist, loading } = useUser();

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const updateViewportHeight = () => {
    setViewportHeight(window.innerHeight);
  };
  useEffect(() => {
    window.addEventListener("resize", updateViewportHeight);
    // Clean up the event listener on component unmount
    return () => window.removeEventListener("resize", updateViewportHeight);
  }, []);

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await signupToWaitlist(email);
    if (response.success) {
      // Handle successful signup
      console.log("added to waitlist");
      setEmail("");
      // TODO: toast the success
      toast.success("Success");
    } else {
      // Handle error
      toast.error(response.message || "An error occurred");
      console.error(response.message);
    }
  };

  return (
    <div
      className="flex flex-col w-full backgroundGradient"
      style={{ minHeight: `${viewportHeight}px` }}
    >
      <MenuBar />
      <div className="flex flex-col flex-grow items-center justify-center px-12 py-12">
        <h1 className="text-5xl md:text-6xl text-center font-semibold mb-4">
          <span className="italic">Super</span> is your virtual you.
        </h1>
        <h3 className="text-lg md:text-xl text-center mx-auto w-full max-w-[400px]">
          Responding to email inhumanely fast <br className="" />— instantly{" "}
          <div className="w-[100px] inline-flex text-left">
            <TextLoop
              texts={["scheduling", "emailing", "ready."]}
              interval={1500}
            />{" "}
          </div>
        </h3>
        <div className="flex flex-col justify-center mt-10 w-full max-w-[400px] mx-auto space-y-4">
          <form onSubmit={handleSubmit} className="flex flex-col space-y-4">
            <input
              placeholder="Enter your email "
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              className="p-4 focus:shadow-md focus:outline-none w-full rounded-full "
              autoComplete="email"
            />
            <Button
              label="Join Waitlist"
              className="bg-emerald-900 px-4 py-4 text-white "
              styleType="primary"
              type="submit"
            />
          </form>
          <p className="text-slate-700 text-md text-center">
            <span className="text-lg">🙎🏼‍♂️👩🏻‍💼👨🏽‍💼</span> Join execs, sales directors,
            founders...{" "}
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
