import React from "react";

type ProgressIndicatorProps = {
  steps: string[];
  currentStepIndex: number;
};

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({
  steps,
  currentStepIndex,
}) => {
  return (
    <div className="w-full flex flex-col">
      <div className="flex items-center">
        {steps.map((step, index) => {
          const isCurrent = currentStepIndex == index;
          return (
            <div key={step} className="flex flex-col items-center flex-1">
              <div
                className={`w-8 h-8 rounded-full ${
                  isCurrent ? "bg-emerald-800 " : "bg-gray-200"
                } flex items-center justify-center text-sm font-semibold text-white`}
              >
                {index + 1}
              </div>
              <div
                className={`text-sm mt-2 text-slate-700 ${
                  isCurrent ? "font-semibold text-slate-900 " : ""
                }`}
              >
                {step}
              </div>
              {index < steps.length - 1 && (
                <div
                  className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
                    isCurrent ? "border-blue-500" : "border-gray-300"
                  }`}
                ></div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressIndicator;
