import { useState } from "react";
import Constants from "../constants";
import { toast } from "react-toastify";
import { useAuth0 } from "@auth0/auth0-react";
import constants from "../constants";

interface SignupResponse {
  success: boolean;
  message?: string;
}

export const useUser = () => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [grants, setGrants] = useState<any[]>([]);
  const [grantsLoading, setGrantsLoading] = useState<boolean>(false); // Added loading indicator for grants
  const [submitOnboardingLoading, setSubmitOnboardingLoading] =
    useState<boolean>(false); // Added loading indicator for submitOnboardingInfo

  const [userInfoLoading, setUserInfoLoading] = useState<boolean>(false);

  const [userInfo, setUserInfo] = useState<any>(null);

  const setEnabledStatus = async (
    enabled: boolean
  ): Promise<SignupResponse> => {
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${Constants.BACKEND_API_ENDPOINT}/user/set_enabled_status`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({ enabled }),
        }
      );
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message || "Failed to update enabled status");
        throw new Error(data.message || "Failed to update enabled status");
      }
      return { success: true, message: data.message };
    } catch (error: any) {
      setError(error.message);
      return { success: false, message: error.message };
    }
  };

  const fetchUserInfo = async (): Promise<void> => {
    setUserInfoLoading(true);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${Constants.BACKEND_API_ENDPOINT}/user/user_info`,
        {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );
      const data = await response.json();
      if (!response.ok) {
        toast.error(data.message || "Failed to fetch user information");
        throw new Error(data.message || "Failed to fetch user information");
      }
      setUserInfo(data);
      setUserInfoLoading(false);
    } catch (error: any) {
      setUserInfoLoading(false);
      setError(error.message);
    }
  };
  const submitOnboardingInfo = async (onboardingInfo: {
    accessCode: string;
    meetingPreferences: string[];
    availability: any[];
    calenderGrantId: string;
    emailGrantId: string;
    callLink: string;
    phoneNumber: string;
  }): Promise<SignupResponse> => {
    setSubmitOnboardingLoading(true); // Set loading to true at the start of the function
    setError(null);
    try {
      const accessToken = await getAccessTokenSilently();
      const response = await fetch(
        `${Constants.BACKEND_API_ENDPOINT}/user/onboarding_info`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify(onboardingInfo),
        }
      );
      const data = await response.json();

      setSubmitOnboardingLoading(false); // Set loading to false after fetching
      if (!response.ok) {
        toast.error(data.message || "An error occurred during onboarding");
        throw new Error(data.message || "An error occurred during onboarding");
      }
      return { success: true };
    } catch (error: any) {
      setSubmitOnboardingLoading(false); // Ensure loading is set to false even if an error occurs
      setError(error.message);
      toast.error(
        `An error occurred: ${error.message}. Email ${constants.HELP_EMAIL} daniel.longo@stanford.edu for help`
      ); // Toast the error message
      return { success: false, message: error.message };
    }
  };
  const signupToWaitlist = async (email: string): Promise<SignupResponse> => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch(
        `${Constants.BACKEND_API_ENDPOINT}/user/waitlist_signup`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ email }),
        }
      );
      const data = await response.json();

      setLoading(false);
      if (!response.ok) {
        toast.error(data.message || "An error occurred");
        throw new Error(data.message || "An error occurred");
      }
      return { success: true };
    } catch (error: any) {
      setLoading(false);
      setError(error.message);
      return { success: false, message: error.message };
    }
  };

  const getGrants = async () => {
    setGrantsLoading(true); // Set grants loading to true at the start of the function
    const accessToken = await getAccessTokenSilently();
    const response = await fetch(
      `${Constants.BACKEND_API_ENDPOINT}/user/grants`,
      {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }
    );
    const data = await response.json();
    console.log(data);
    setGrants(data.grants);
    setGrantsLoading(false); // Set grants loading to false after fetching
  };

  return {
    grants,
    grantsLoading, // Return the new grants loading indicator
    signupToWaitlist,
    getGrants,
    loading,
    error,
    submitOnboardingInfo,
    submitOnboardingLoading,
    userInfo,
    fetchUserInfo,
    userInfoLoading,
    setEnabledStatus,
  }; // Return the new loading indicator
};

export default useUser;
