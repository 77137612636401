import React, { FC, useState } from "react";
import RadioButtonGroup from "../../components/UICore/RadioButtonGroup";
import AvailabilitySelector from "../../components/AvailabilitySelector";
import Input from "../../components/UICore/Input";
import Button from "../../components/UICore/Button";
import { AiOutlineClose } from "react-icons/ai";

interface HourBlock {
  hour: number; // This is the hour in 24-hour format
  available: boolean;
}

const preset_preferences = [
  "Cluster meetings",
  "Leave gaps after short meetings",
  "Try to schedule meetings in the morning",
  "Try to leave Friday afternoons free",
];

interface MeetingPreferencesProps {
  meetingPreferences: string[];
  setMeetingPreferences: (meetingPreferences: string[]) => void;
  availability: HourBlock[][];
  setAvailability: (newAvailability: HourBlock[][]) => void;
}

const MeetingPreferences: FC<MeetingPreferencesProps> = ({
  meetingPreferences,
  setMeetingPreferences,
  availability,
  setAvailability,
}) => {
  const [preferences, setPreferences] = useState<string[]>(preset_preferences);
  const [customPreference, setCustomPreference] = useState<string>("");
  const addCustomPreference = (preference: string): void => {
    if (preferences.includes(preference)) {
      return;
    }
    if (preference === "") {
      return;
    }
    setPreferences([...preferences, preference]);
    setMeetingPreferences([...meetingPreferences, preference]);
    setCustomPreference("");
  };
  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col space-y-2 mb-4">
        <h3 className="text-lg text-slate-700 py-2">Meeting Preferences</h3>
        {Array.from(new Set([...preferences, ...meetingPreferences])).map(
          (preference) => (
            <div
              key={preference}
              className={`items-center flex flex-row  justify-between cursor-pointer p-2 rounded-md border hover:shadow-sm ${
                meetingPreferences.includes(preference)
                  ? "border-emerald-800 bg-emerald-50 text-emerald-800"
                  : "border-gray-300 text-gray-500"
              }`}
              onClick={() => {
                if (meetingPreferences.includes(preference)) {
                  setMeetingPreferences(
                    meetingPreferences.filter((pref) => pref !== preference)
                  );
                } else {
                  setMeetingPreferences([...meetingPreferences, preference]);
                }
              }}
            >
              {preference}
              {!preset_preferences.includes(preference) && (
                <AiOutlineClose
                  className="inline ml-2 text-red-700"
                  onClick={(e) => {
                    e.stopPropagation();
                    setPreferences(
                      preferences.filter((pref) => pref !== preference)
                    );
                    setMeetingPreferences(
                      meetingPreferences.filter((pref) => pref !== preference)
                    );
                  }}
                />
              )}
            </div>
          )
        )}
        <div className="flex flex-row space-x-2 w-full items-center flex-grow">
          <input
            placeholder="Add custom preference"
            className="flex-grow rounded-md flex-row p-2 border border-gray-300 text-gray-800"
            value={customPreference}
            onChange={(e) => setCustomPreference(e.target.value)}
          />
          <Button
            label="Add"
            styleType="secondary"
            onClick={(): void => {
              addCustomPreference(customPreference);
            }}
            className="w-[100px]  py-2 rounded-md border-[1.3px] hover:bg-emerald-50"
          />
        </div>
      </div>
      <h3 className="text-lg text-slate-700 py-2">Availability</h3>
      <AvailabilitySelector
        availability={availability}
        setAvailability={setAvailability}
      />
    </div>
  );
};

export default MeetingPreferences;
