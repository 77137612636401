import { FC, useState, useEffect } from "react";

interface SelectWithRefreshProps {
  options: Array<{ label: string; value: string }>;
  value: string;
  onSelect: (value: string) => void;
  onRefresh: () => Promise<void>;
  label?: string; // Added optional label prop
}

const SelectWithRefresh: FC<SelectWithRefreshProps> = ({
  options,
  value,
  onSelect,
  onRefresh,
  label,
}) => {
  const [selectedValue, setSelectedValue] = useState(value);

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    onSelect(newValue);
  };

  const handleRefresh = async () => {
    await onRefresh();
  };

  return (
    <div>
      {label && (
        <label className="block text-sm font-medium text-gray-700  ml-1 ">
          {label}
        </label>
      )}
      {/* Conditionally render label if it exists */}
      <select
        value={selectedValue}
        onChange={handleSelectChange}
        className="bg-slate-100 border border-slate-200  focus:outline-blue-800 p-2 w-[200px] rounded"
      >
        {options.map((option) => (
          <option key={option.value} value={option.value} className="p-2">
            {option.label}
          </option>
        ))}
        {/* <option value="refresh" onClick={handleRefresh}>
          Refresh
        </option> */}
      </select>
    </div>
  );
};

export default SelectWithRefresh;
