import React, { FC, useState } from "react";
import Card from "../../components/UICore/Card";
import AccessCode from "./AccessCode";
import MeetingPreferences from "./MeetingPreferences";
import Integrations from "./Integrations";
import ProgressIndicator from "../../components/UICore/ProgressIndicator";
import Button from "../../components/UICore/Button";
import MenuBar from "../../components/MenuBar";
import Footer from "../../components/Footer";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingPage from "../loading";
import { toast } from "react-toastify";
import useUser from "../../hooks/useUser";

interface HourBlock {
  hour: number; // This is the hour in 24-hour format
  available: boolean;
}
// Initialize default availability for a 5-day work week, assuming each day has 18 hours starting from 5 AM to 10 PM
const defaultAvailability: HourBlock[][] = Array(7)
  .fill(null)
  .map((_, dayIndex) =>
    Array.from({ length: 18 }, (_, hourIndex) => ({
      hour: 5 + hourIndex, // Starting from 5 AM
      // Default availability from 9 AM (hour 9) to 5 PM (hour 17 in 24-hour format)
      available: dayIndex < 5 && 9 <= 5 + hourIndex && 5 + hourIndex < 17,
    }))
  );

const SignUp: FC = () => {
  const steps = ["Access", "Preferences", "Integrations"];
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const { submitOnboardingInfo, submitOnboardingLoading } = useUser();
  const [formData, setFormData] = useState({
    accessCode: "",
    meetingPreferences: ["Cluster meetings", "Leave gaps after short meetings"],
    availability: defaultAvailability,
    calenderGrantId: "",
    emailGrantId: "",
    callLink: "",
    phoneNumber: "",
  });

  const handleSubmission = async () => {
    console.log("Form Data", formData);
    // check if all fields are filled
    if (formData.calenderGrantId === "") {
      toast.error("Please enter connect and choose an email for your calendar");
    } else if (formData.emailGrantId === "") {
      toast.error("Please enter connect and choose an email for your email");
    } else if (formData.callLink === "") {
      toast.error("Please enter your call link");
    } else {
      // submit form
      console.log("Form Submitted", formData);
      await submitOnboardingInfo(formData);
      window.location.href = "/home";
      // if (!submitOnboardingLoading) {
      //   alert("Form Submitted");
      // }
    }
  };

  const goToPreviousStep = () =>
    setCurrentStepIndex((index) => Math.max(0, index - 1));
  const goToNextStep = () =>
    setCurrentStepIndex((index) => Math.min(steps.length - 1, index + 1));

  return (
    <div className="flex flex-col backgroundGradient min-h-screen w-full">
      <MenuBar />
      <div className="flex flex-col justify-center items-center min-h-full w-full flex-grow">
        <Card className="flex flex-col space-y-4 w-full max-w-lg my-14 mx-20 items-center justify-center px-8 py-16">
          <h1 className="text-xl text-[#45403d] pb-4">Get Started</h1>
          <ProgressIndicator
            steps={steps}
            currentStepIndex={currentStepIndex}
          />
          <div className="py-6 flex w-full items-center justify-center">
            {currentStepIndex === 0 && (
              <AccessCode
                accessCode={formData.accessCode}
                setAccessCode={(accessCode) =>
                  setFormData({ ...formData, accessCode })
                }
              />
            )}
            {currentStepIndex === 1 && (
              <MeetingPreferences
                meetingPreferences={formData.meetingPreferences}
                setMeetingPreferences={(meetingPreferences) =>
                  setFormData({ ...formData, meetingPreferences })
                }
                availability={formData.availability}
                setAvailability={(availability) =>
                  setFormData({ ...formData, availability })
                }
              />
            )}
            {currentStepIndex === 2 && (
              <Integrations
                calenderGrantId={formData.calenderGrantId}
                emailGrantId={formData.emailGrantId}
                callLink={formData.callLink}
                phoneNumber={formData.phoneNumber}
                setCalenderGrantId={(calenderGrantId) =>
                  setFormData({ ...formData, calenderGrantId })
                }
                setEmailGrantId={(emailGrantId) =>
                  setFormData({ ...formData, emailGrantId })
                }
                setCallLink={(callLink) =>
                  setFormData({ ...formData, callLink })
                }
                setPhoneNumber={(phoneNumber) =>
                  setFormData({ ...formData, phoneNumber })
                }
              />
            )}
          </div>
          <div className="flex space-x-2 items-center justify-center ">
            {currentStepIndex > 0 && (
              <button
                // styleType="secondary"
                className="rounded-md py-2 bg-white text-black-800 hover:shadow-sm px-4 border border-emerald-800 text-emerald-800"
                onClick={goToPreviousStep}
                // label="Previous"
              >
                Previous
              </button>
            )}
            {currentStepIndex === steps.length - 1 ? (
              <button
                className="px-4 rounded-md py-2 bg-emerald-800 text-white  hover:bg-emerald-900"
                onClick={handleSubmission}
                disabled={submitOnboardingLoading}
              >
                {submitOnboardingLoading ? "Submitting..." : "Submit"}
              </button>
            ) : (
              <button
                className="px-4 py-2  rounded hover:shadow-sm bg-emerald-800 text-white  hover:bg-emerald-900"
                onClick={goToNextStep}
              >
                Next
              </button>
            )}
          </div>
        </Card>
      </div>
      <Footer />
    </div>
  );
};

export default withAuthenticationRequired(SignUp, {
  returnTo: "/signup",
  onRedirecting: () => <LoadingPage />,
});
