// import React, { FC } from "react";

// const Footer: FC = () => {
//   return (
//     <footer className=" text-white text-center py-5 fixed inset-x-0 bottom-0">
//       <p className="text-slate-800">
//         daniel.longo@stanford.edu | 2024 ddl labs
//       </p>
//     </footer>
//   );
// };

// export default Footer;
import React, { FC } from "react";
import constants from "../constants";

const Footer: FC = () => {
  return (
    <footer className="text-white text-center py-5">
      <p className="text-[#8f8c86]">{constants.HELP_EMAIL} | 2024 ddl labs</p>
    </footer>
  );
};

export default Footer;
