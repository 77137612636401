import React, { FC, useEffect } from "react";
import Input from "../../components/UICore/Input";
import { useSearchParams } from "react-router-dom";

interface AccessCodeProps {
  accessCode: string;
  setAccessCode: (accessCode: string) => void;
}
const AccessCode: FC<AccessCodeProps> = ({ accessCode, setAccessCode }) => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlAccessCode = searchParams.get("access_code");
    if (urlAccessCode) {
      setAccessCode(urlAccessCode);
    }
  }, [searchParams, setAccessCode]);

  return (
    <div className="min-h-[100px] flex flex-col justify-center">
      <Input
        label="Access Code"
        placeholder="1234"
        value={accessCode}
        onChange={(value) => setAccessCode(value.target.value)}
      />
    </div>
  );
};

export default AccessCode;
