import React, { FC, ReactNode } from "react";

interface CardProps {
  children?: ReactNode;
  className?: string;
}

const Card: FC<CardProps> = ({ children, className }) => {
  return (
    <div className={`flex  bg-white shadow-md p-6 rounded-lg ${className}`}>
      {children}
    </div>
  );
};

export default Card;
