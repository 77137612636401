import React, { FC, useState, useEffect } from "react";

interface TextLoopProps {
  texts: string[];
  interval: number; // Interval in milliseconds
}

const TextLoop: FC<TextLoopProps> = ({ texts, interval }) => {
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [fade, setFade] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFade(false);
      setTimeout(() => {
        setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
        setFade(true);
      }, 500); // Half of your transition duration to smoothly transition between texts
    }, interval);

    return () => clearTimeout(timer);
  }, [texts, interval, currentTextIndex]);

  return (
    <span
      className={`transition-opacity duration-500 w-[250px] font-semibold  ${
        fade ? "opacity-100" : "opacity-0"
      }`}
    >
      {texts[currentTextIndex]}
    </span>
  );
};

export default TextLoop;
