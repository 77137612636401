import React, { FC } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "./UICore/Button";

const MenuBar: FC = () => {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const isLanding = window.location.pathname === "/";

  const handleLogin = () => {
    loginWithRedirect();
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <div className="flex justify-between items-center min-mx-10 py-5 px-10 ">
      <a href="/">
        {" "}
        <div className="text-2xl font-semibold italic  ">Super</div>
      </a>
      <div className="flex space-x-5">
        {isAuthenticated ? (
          <>
            {isLanding && (
              <Button
                label="Dashboard"
                onClick={() => window.location.replace("/home")}
              />
            )}
            <Button
              label="Logout"
              onClick={handleLogout}
              styleType="secondary"
            />
          </>
        ) : (
          <Button label="Login" onClick={handleLogin} />
        )}
      </div>
    </div>
  );
};

export default MenuBar;
