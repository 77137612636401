import React, { FC, useEffect, useState } from "react";
import MenuBar from "../../components/MenuBar";
import Card from "../../components/UICore/Card";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingPage from "../loading";
import useUser from "../../hooks/useUser";
import AvailabilitySelector from "../../components/AvailabilitySelector";
import ToggleSwitch from "../../components/UICore/ToggleSwitch";
import Footer from "../../components/Footer";
import LoadingSpinbox from "../../components/UICore/LoadingSpinbox";
import constants from "../../constants";
import Button from "../../components/UICore/Button";

const preferences = [
  "Cluster meetings",
  "Leave gaps after short meetings",
  "Phone Number",
  "Call Link",
];
const Home: FC = () => {
  const { fetchUserInfo, userInfo, userInfoLoading, setEnabledStatus } =
    useUser();
  useEffect(() => {
    fetchUserInfo();
  }, []);
  const [isNewUser, setIsNewUser] = useState(true);

  useEffect(() => {
    if (userInfo) {
      console.log("User Info", userInfo);
      // if has onboarding_info, then not a new user
      if (userInfo.onboarding_info) {
        setIsNewUser(false);
      }
    }
  }, [userInfo]);

  console.log("is new user", isNewUser);

  return (
    <div className="flex flex-col backgroundGradient min-h-screen w-full ">
      <MenuBar />
      <div className="flex flex-col items-left min-h-full flex-grow mx-auto w-full px-10">
        <div className="flex justify-left items-center pt-4">
          <h1 className="text-xl ">
            Your Account{" "}
            <a href="/signup?access_code=74sx">
              {" "}
              <h1 className="underline cursor-pointer inline-flex ">
                (Edit settings)
              </h1>
            </a>
          </h1>
        </div>
        {isNewUser && !userInfoLoading && (
          <Card className="min-w-[400px] min-h-[100px] flex flex-col my-4">
            <h3 className="text-xl text-[#45403d] pb-4">
              Welcome, looks like you're new here
            </h3>

            <a href="/signup" className="text-blue-800 underline">
              <Button
                styleType="primary"
                label="Get Started"
                className="rounded-md"
              />
            </a>
          </Card>
        )}
        {userInfoLoading && (
          <div className="flex justify-center items-center p-4 -mx-8 min-h-[calc(100vh-64px)] flex-col">
            <LoadingSpinbox />
            <h1 className="text-lg font-semibold">Loading...</h1>
          </div>
        )}
        {!isNewUser && (
          <div className="flex flex-col space-y-4 w-full my-4">
            <Card className="min-w-[400px] min-h-[100px] flex flex-col">
              <h3 className="text-xl text-[#45403d] pb-4">Status</h3>
              <div className="flex flex-row items-center">
                {userInfo?.enabled ? (
                  <p className="text-emerald-800 font-large px-2 py-1 rounded-lg bg-emerald-50">
                    Enabled
                  </p>
                ) : (
                  <p className="text-rose-800 font-large px-2 py-1 rounded-lg bg-rose-50">
                    Disabled
                  </p>
                )}
                <ToggleSwitch
                  className="ml-4"
                  label={""}
                  checked={userInfo?.enabled}
                  onChange={function (checked: boolean): void {
                    setEnabledStatus(checked);
                    window.location.reload();
                  }}
                />
              </div>
              <span className=" py-2">
                For help contact {constants.HELP_EMAIL}
              </span>
            </Card>

            <Card className="min-w-[400px] min-h-[100px] flex-col flex items-start">
              <h3 className="text-xl text-[#45403d] pb-4">Availability</h3>
              <AvailabilitySelector
                availability={userInfo?.onboarding_info.availability}
                setAvailability={function setAvailability() {
                  // throw new Error("Function not implemented.");
                }}
              />
            </Card>
            <Card className="min-w-[400px] min-h-[100px] flex flex-col">
              <h3 className="font-semibold">Settings</h3>
              <div className="flex flex-col space-y-4">
                <p className="flex-row flex items-center">
                  Preferences
                  <div className="flex-row flex space-x-2 mx-2">
                    {userInfo?.onboarding_info.meetingPreferences.map(
                      (preference: string) => (
                        <p
                          key={preference}
                          className="bg-[#dad7d0] px-2 py-1 rounded-lg inline"
                        >
                          {preference}
                        </p>
                      )
                    )}
                  </div>
                </p>
                <p>
                  Phone Number{" "}
                  <p className="bg-[#dad7d0] px-2 py-2 mx-2 rounded-lg inline">
                    {userInfo?.onboarding_info.phoneNumber}
                  </p>
                </p>
                <p>
                  Call Link
                  <p className="bg-[#dad7d0] px-2 py-2 mx-2 rounded-lg inline">
                    {userInfo?.onboarding_info.callLink}
                  </p>
                </p>
              </div>
            </Card>
            <Card className="min-w-[400px] min-h-[100px] flex-col">
              <h3 className="text-xl text-[#45403d] pb-4">
                Connected Accounts
              </h3>
              <div className="space-y-4">
                <p>
                  Email{" "}
                  <p className="bg-[#dad7d0] px-2 py-2 mx-2 rounded-lg inline">
                    {userInfo?.onboarding_info.emailGrantId}
                  </p>
                </p>
                <p>
                  Calendar{" "}
                  <p className="bg-[#dad7d0] px-2 py-2 mx-2 rounded-lg inline">
                    {userInfo?.onboarding_info.calenderGrantId}
                  </p>
                </p>
                <p>
                  <a href="" className="text-red-500 underline cursor-pointer ">
                    Delete Connection
                  </a>
                </p>
              </div>
            </Card>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default withAuthenticationRequired(Home, {
  returnTo: "/home",
  onRedirecting: () => <LoadingPage />,
});
