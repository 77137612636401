import React, { FC } from "react";

interface ButtonProps {
  label?: string;
  className?: string;
  onClick?: () => void;
  styleType?: "primary" | "secondary" | "danger";
  type?: "button" | "submit" | "reset";
  disabled?: boolean; // Added optional disabled prop
}

const typeClasses = {
  primary: "bg-emerald-900 hover:shadow-lg text-white",
  secondary:
    "border-2 border-emerald-900 hover:border-gray-700 text-emerald-800 hover:text-emerald-900",
  danger: "bg-red-500 hover:bg-red-700 text-white",
};

const Button: FC<ButtonProps> = ({
  label,
  onClick,
  className,
  styleType = "primary",
  type = "button",
  disabled = false, // Set default value for disabled prop
}) => {
  const typeClass = typeClasses[styleType];
  return (
    <button
      className={`px-4 py-2 rounded-full ${typeClass} ${className}`}
      onClick={onClick}
      type={type}
      disabled={disabled} // Apply disabled prop to button element
    >
      {label}
    </button>
  );
};

export default Button;
