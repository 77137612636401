import React, { FC, useEffect } from "react";
import constants from "../../constants";
import { useAuth0 } from "@auth0/auth0-react";
import useUser from "../../hooks/useUser";
import Button from "../../components/UICore/Button";
import Input from "../../components/UICore/Input";
import SelectWithRefresh from "../../components/SelectWithRefersh";
import LoadingSpinbox from "../../components/UICore/LoadingSpinbox";

interface IntegrationsProps {
  calenderGrantId: string;
  emailGrantId: string;
  callLink: string;
  phoneNumber: string;
  setCalenderGrantId: (calenderGrantId: string) => void;
  setEmailGrantId: (emailGrantId: string) => void;
  setCallLink: (callLink: string) => void;
  setPhoneNumber: (phoneNumber: string) => void;
}

const Integrations: FC<IntegrationsProps> = ({
  calenderGrantId,
  emailGrantId,
  callLink,
  phoneNumber,
  setCalenderGrantId,
  setEmailGrantId,
  setCallLink,
  setPhoneNumber,
}) => {
  const { user } = useAuth0();
  const { grants, getGrants, grantsLoading } = useUser(); // Destructure grantsLoading from useUser
  const redirectUrl = `${constants.BACKEND_API_ENDPOINT}/webhooks/hosted_auth`;

  useEffect(() => {
    console.log("Grants changed:", grants, calenderGrantId, emailGrantId);
    if (grants.length > 0) {
      console.log(
        "Setting default grant ids",
        calenderGrantId == "",
        emailGrantId == ""
      );
      if (calenderGrantId == "") {
        setCalenderGrantId(grants[0].email);
      }
      if (emailGrantId == "") {
        setEmailGrantId(grants[0].email);
      }
    }
  }, [grants]);

  return (
    <div className="flex flex-col items-start justify-start pb-4 space-y-2 w-full">
      <h3 className="text-lg text-slate-700 py-2">
        Connect Calendar and Email
      </h3>
      <a
        href={`${constants.NYLAS_HOSTED_AUTH_URL}&state=${user?.sub}&redirect_uri=${redirectUrl}`}
        className="text-blue-800 underline"
        target="_blank"
      >
        <button className="bg-emerald-800 text-white  hover:bg-emerald-900 hover:shadow-md   py-2 px-4 rounded my-2">
          <p>Add Account</p>{" "}
        </button>
      </a>
      <a
        className="text-blue-800 underline cursor-pointer pb-2"
        onClick={async () => {
          try {
            await getGrants();
            console.log("Grants refreshed.");
            // alert("Grants refreshed. Check console for details.");
          } catch (error) {
            console.error("Error fetching grants:", error);
            alert("Failed to refresh grants.");
          }
        }}
      >
        Refresh connections{" "}
      </a>
      {grantsLoading ? (
        <div className="w-full flex flex-col items-center justify-center">
          <LoadingSpinbox textBelow="Loading connected accounts..." />
        </div>
      ) : grants.length === 0 ? (
        <p className="text-red-500 text-sm ">
          No connected accounts. Please "add account" and "refresh connections"
        </p>
      ) : (
        <>
          <SelectWithRefresh
            label="Calendar Account"
            options={grants.map((grant) => ({
              label: grant.email,
              value: grant.email,
              // value: grant.grant_id,
            }))}
            value={calenderGrantId}
            onSelect={(value) => setCalenderGrantId(value)}
            onRefresh={getGrants}
          />
          <SelectWithRefresh
            label="Email Account"
            options={grants.map((grant) => ({
              label: grant.email,
              value: grant.email,
              // TODO(daniel): refactor this so clear if email or grant id
              // value: grant.grant_id,
            }))}
            value={emailGrantId}
            onSelect={(value) => setEmailGrantId(value)}
            onRefresh={getGrants}
          />
        </>
      )}
      <h3 className="text-lg text-slate-700 py-2">Meeting Details</h3>
      <Input
        label="Call Link"
        placeholder="https://www.zoom.us/me..."
        value={callLink}
        onChange={(e) => setCallLink(e.target.value)}
        className="w-[250px]"
      />
      <Input
        label="Phone Number"
        placeholder="(650)-555-5555"
        value={phoneNumber}
        onChange={(e) => setPhoneNumber(e.target.value)}
        className="w-[250px]"
      />
    </div>
  );
};

export default Integrations;
